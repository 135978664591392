
// Large devices (desktops, less than 1200px)
@media (max-width: 1200.98px) {
	div.section-main-carousel {
        .container-margin-top {
            .main-carousel-description {
                .description-service {
                    max-width: 75%;
                    padding: 40px;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

    div.section-main-carousel {
        .container-margin-top {
            .main-carousel-description {
                .description-image {
                    display: none;
                }
                .description-service {
                    max-width: 100%;
                }
            }
        }
    }

    div.section-offer {
        .offer-flexbox {
            flex-direction: column;
            justify-content: center;
    
            .flexbox-image {
                margin-left: 0;
                margin-top: 40px;
            }
        }
    }

    div.section-footer {
        .footer-flexbox {
            flex-direction: column;
            align-items: center;
            text-align: center;
    
            iframe {
               margin: 10px 0;
            }
    
            .flexbox-contact {
                align-items: center;
                text-align: center;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    div.section-header {
        .navbar-top {
            .navbar-collapse {
                padding: 0 20px;
            }
        }
    }

    div.section-main-carousel {
        .container-margin-top {
            position: static;
            margin-top: -150px;
            
            .main-carousel-description {
                .description-service {
                    h1 {
                        font-size: 46px;
                    }
                }
            }
        }
    }

    div.section-gallery {
        .gallery-flexbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
    
            a {
                width: 48.5%;
            }
        }
    }

    div.section-partners {
        .section-heading {
            p {
                width: 100%;
            }
        }
    }

    div.section-cta {
        .cta-flexbox {
            flex-direction: column;
            align-items: center;
            
            .btn-default {
                order: 2;
                margin-top: 20px;
            }
    
            .flexbox-service {
                text-align: center;
                p {
                    width: 100%;
                }
            }
        }
    }

    section.section-team {
        .team-wrapper {
            .team-person {
                width: 48%;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    div.section-header {
        .navbar-top {
            .navbar-collapse {
                padding: 0 20px;
            }
        }

        .header-flexbox {
            justify-content: center;
            text-align: center;
            .flexbox-brand {
                img {
                    width: 150px;
                }
            }
        }
    }

    div.section-main-carousel {
        .container-margin-top {
            .main-carousel-description {
                .description-service {
                    h1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }

    section.section-team {
        padding: 40px 0;

        .team-wrapper {
            .team-person {
                width: 100%;
                margin: 10px 0;
                padding: 15px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

}