@font-face {
	font-family: 'akrobatlight';
	src: url('../fonts/akrobat-light-webfont.woff2') format('woff2'),
		 url('../fonts/akrobat-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'akrobatregular';
	src: url('../fonts/akrobat-regular-webfont.woff2') format('woff2'),
		 url('../fonts/akrobat-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'akrobatextrabold';
	src: url('../fonts/akrobat-extrabold-webfont.woff2') format('woff2'),
		 url('../fonts/akrobat-extrabold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
	min-height: 100%;
	font-family: @font-regular;
	overflow-x: hidden;

	ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: @color2;
	}

	a {
		text-decoration: none !important;
	}

	.btn-default {
		text-transform: uppercase;
		font-family: @font-extrabold;
		font-size: 16px;
		border: none;
		padding: 15px 20px;
		color: #fff;
		background: @color2;
		transition: all 0.5s;

		&:hover {
			opacity: 0.8;
			color: #fff;
			background: @color2;
		}
	}

	#return-to-top {
		position: fixed;
		z-index: 999;
		bottom: 40px;
		right: 40px;
		border: 1px solid @color1;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background: @color1;
		border: 2px solid #fff;
		padding-top: 3px;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		display: none;
		font-size: 24px;
		transition: all 0.3s ease;
		color: #fff;
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

div.section-header {
	position: relative;

	.header-figure {
		position: absolute;
		z-index: -1;
		top: -5vw;
		right: 0;
		clip-path: polygon(30% 0, 100% 0, 100% 100%, 13% 100%, 0 57%);
		height: 40vw;
		width: 60vw;
		background: @color2;
		opacity: 0.05;
	}

	.navbar-top {
		min-height: auto;
		margin-bottom: 0;
		border: none;
		border-radius: 0;
		background: none;

		.navbar-collapse {
			padding-left: 0;
		}

		.navbar-nav {
			li {
				margin-right: 40px;

				a {
					padding: 20px 0;
					font-size: 13px;
					text-transform: uppercase;
					transition: all 0.5s;
					color: @color2;

					&:hover {
						color: @color1;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.header-flexbox {
		margin-top: 30px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-number {
			display: flex;
			align-items: center;

			i {
				color: @color1;
				font-size: 30px;
				margin-right: 20px;
			}

			.number-text {
				p {
					font-size: 18px;
					color: @color1;
					margin-bottom: 5px;
				}

				a {
					font-size: 42px;
					line-height: 42px;
					color: @color2;
					font-family: @font-extrabold;
				}
			}
		}
	}

	.navbar-bottom {
		margin-top: 60px;
		padding-top: 20px;
		min-height: auto;
		margin-bottom: 0;
		border: none;
		border-top: 3px solid @gray-light;
		border-radius: 0;
		background: none;

		.navbar-collapse {
			padding-left: 0;
			padding-right: 0;
		}

		.navbar-nav {
			width: 100%;
			display: flex;
			justify-content: space-between;

			li {
				a {
					padding: 10px 0;
					font-size: 15px;
					text-transform: uppercase;
					transition: all 0.5s;
					color: @gray-light;
					font-family: @font-extrabold;

					&:hover {
						color: @color1;
					}
				}
			}

			&:before, &:after {
				content: none;
			}
		}
	}
}

div.section-main-carousel {
	margin-top: 50px;
	position: relative;
	z-index: 111;

	.main-carousel-background-wrapper {
		position: relative;
		background: @color1;
		clip-path: polygon(0 11%, 100% 0, 100% 100%, 0% 100%);
		padding-top: 4px;

		.main-carousel-background {
			width: 100%;
			clip-path: polygon(0 11%, 100% 0, 100% 100%, 0% 100%);
		}
	}

	.container-margin-top {
		position: absolute;
		width: 100%;
		bottom: 0;
		right: 0;

		.main-carousel-description {
			display: flex;
			justify-content: space-between;

			.description-image {
				display: flex;
				align-items: flex-end;
				
				img {
					width: 100%;
				}
			}

			.description-service {
				align-self: center;
				max-width: 45%;
				background: #f5f6f6;
				padding: 60px 50px 50px 50px;
				clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);

				h1 {
					font-size: 55px;
					color: @color2;
					text-transform: uppercase;
				}

				h2 {
					margin: 10px 0;
					color: @color1;
					font-size: 24px;
					text-transform: uppercase;
				}

				p {
					color: @gray-light;
					line-height: 24px;
				}
			}
		}
	}
}

div.section-gallery {
	position: relative;
	z-index: 111;
	margin-top: 50px;

	.section-heading {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			font-size: 55px;
			color: @color2;
			text-transform: uppercase;
		}

		h3 {
			margin: 10px 0;
			color: @color1;
			font-size: 24px;
			text-transform: uppercase;
		}
	}

	.gallery-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		a {
			margin-top: 20px;
			width: 18%;

			img {
				width: 100%;
				border-radius: 5px;
			}

			&.service-hidden {
				padding: 0;
				margin: 0;
				visibility: hidden;
			}
		}
	}
}

div.section-partners {
	position: relative;
	z-index: 111;
	margin-top: 50px;

	.section-heading {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			font-size: 55px;
			color: @color2;
			text-transform: uppercase;
		}

		h3 {
			margin: 10px 0;
			color: @color1;
			font-size: 24px;
			text-transform: uppercase;
		}

		p {
			width: 50%;
			color: @gray-light;
			line-height: 24px;
		}
	}

	.partners-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-service {
			margin-top: 50px;
			width: calc(100% / 15);

			img {
				width: 100%;
				filter: grayscale(100%);
				opacity: 0.7;
				transition: all 0.3s;

				&:hover {
					filter: grayscale(0);
					opacity: 1;
				}
			}

			&.service-hidden {
				padding: 0;
				margin: 0;
				visibility: hidden;
			}
		}
	}
}

div.section-cta {
	margin-top: 50px;
	padding: 50px 0;
	background: @color1;
	position: relative;

	.cta-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 111;

		.flexbox-service {
			text-align: right;

			h2 {
				font-size: 55px;
				color: @color2;
				text-transform: uppercase;
			}

			h3 {
				margin: 10px 0;
				color: #fff;
				font-size: 24px;
				text-transform: uppercase;
			}

			p {
				width: 55%;
				float: right;
				color: @gray-lighter;
				line-height: 24px;
			}
		}
	}
}

div.section-offer {
	position: relative;
	padding: 50px 0;
		
	.offer-figure {
		position: absolute;
		bottom: 0;
		left: -55vh;
		height: 100vw;
		width: 72vw;
		background: @color2;
		clip-path: polygon(56% 0, 0% 100%, 100% 100%);
		opacity: 0.05;
	}

	.offer-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.flexbox-image {
			min-width: 360px;
			margin-left: 100px;

			img {
				width: 100%;
			}
		}

		.flexbox-description {
			h2 {
				font-size: 55px;
				color: @color2;
				text-transform: uppercase;
			}

			h3 {
				margin: 10px 0;
				color: @color1;
				font-size: 24px;
				text-transform: uppercase;
			}

			p {
				color: @gray-light;
				line-height: 24px;
			}

			.btn {
				margin-top: 30px;
			}
		}
	}
}

section.section-team {
	position: relative;
	padding: 40px 0 80px;
	
	.team-figure {
		position: absolute;
		bottom: 0;
		left: -55vh;
		height: 100vw;
		width: 72vw;
		background: @color2;
		clip-path: polygon(56% 0, 0% 100%, 100% 100%);
		opacity: 0.05;
	}

	h2 {
		font-size: 55px;
		color: #26393d;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 5px;
	}

	h2 ~ p {
		color: #51d994;
		font-size: 24px;
		text-transform: uppercase;
		text-align: center;
	}

	.team-wrapper {
		padding-top: 40px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.team-person {
			width: 31%;
			border: 1px solid @color1;
			margin: 1%;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			ul {
				margin-top: 5px;
				list-style: none;
			}
		}
	}
}

div.section-footer {
	padding-top: 50px;
	background: @color2;

	.footer-flexbox {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.flexbox-navigation {
			ul {
				li {
					p {
						color: @color1;
						font-size: 24px;
						text-transform: uppercase;
					}

					a {
						font-size: 18px;
						color: lighten(@color2, 20%);
					}
				}
			}
		}

		iframe {
			border: none;
			border-radius: 7px;
		}

		.flexbox-contact {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;

			a {
				font-size: 32px;
				color: lighten(@color2, 20%);
				font-family: @font-extrabold;
			}

			p {
				color: lighten(@color2, 20%);

				&.heading {
					margin-top: 20px;
				}
			}

			.contact-facebook {
				margin-top: 30px;

				img {
					margin-bottom: 0;
				}
			}
		}
	}

	.footer-credits {
		padding: 50px 0 20px 0;
		text-align: center;

		p {
			color: lighten(@color2, 20%);

			a {
				color: @color1;
			}
		}
	}
}